import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-virtuous-sweetbitter',
  templateUrl: './virtuous-sweetbitter.component.html',
  styleUrls: ['./virtuous-sweetbitter.component.scss'],
})
export class VirtuousSweetBitterComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
