import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-umami',
  templateUrl: './umami.component.html',
  styleUrls: ['./umami.component.scss'],
})
export class UmamiComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
