import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-virtuous-organoleptic-trial',
  templateUrl: './virtuous-organoleptic-trial.component.html',
  styleUrls: ['./virtuous-organoleptic-trial.component.scss'],
})
export class VirtuousOrganolepticTrialComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
