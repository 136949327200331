import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-sweetbitter',
  templateUrl: './sweetbitter.component.html',
  styleUrls: ['./sweetbitter.component.scss'],
})
export class SweetBitterComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
