import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-virtuous-multitaste',
  templateUrl: './virtuous-multitaste.component.html',
  styleUrls: ['./virtuous-multitaste.component.scss'],
})
export class VirtuousMultitasteComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
