import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-virtuous-analyse-foods',
  templateUrl: './virtuous-analyse-foods.component.html',
  styleUrls: ['./virtuous-analyse-foods.component.scss'],
})
export class VirtuousAnalyseFoodsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
