import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-umami',
  templateUrl: './multitaste.component.html',
  styleUrls: ['./multitaste.component.scss'],
})
export class MultitasteComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
